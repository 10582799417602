<template>
    <div class="modal" style="display:flex">

        <div class="modalcall" v-if="car || dealer">
            <div class="modal__wrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>Call Seller</h1>
                </div>
                <div class="modalcall__content">
<!--                    <p>The seller has more than one phone number, which would you like to call?</p>-->
                    <ul>
                        <li v-for="(item,idx) in getPhones" v-bind:key="idx">
                            <a :href="formattedPhoneNumber(item)">{{ item }}</a>
                        </li>
                        <li v-if="mobilePhoneNumber"><a :href="formattedMobilePhoneNumber">{{ mobilePhoneNumber }}</a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "ModalCallSeller",
        props: ['car', 'dealer'],
        data: function() {
            return {
            }
        },
        mounted(){
        },

        computed: {
            getPhones(){
                let res = [];
                if(this.car != null &&  this.car.dealer != null) {
                    res = this.car.dealer.phone.split('/');
                }else if(this.dealer != null){
                    res = this.dealer.phone.split('/');
                }
                return res;
            },
            formattedMobilePhoneNumber() {
                let res = '';
                if(this.car != null){
                    res =  'tel:' + this.car.dealer.mobile;
                }else if(this.dealer != null){
                    res = 'tel:' + this.dealer.mobile;
                }
                return res;
            },

            mobilePhoneNumber(){
                let res = '';
                if(this.car != null){
                    res =  this.car.dealer.mobile;
                }else if(this.dealer != null){
                    res = this.dealer.mobile;
                }
                return res;
            }
        },

        methods: {
            closeModal() {
                EventBus.$emit('closeModal');
            },
            formattedPhoneNumber(number) {
                return  'tel:' + number;
            },
            getCountPhoneNumbers(){
                let sum = 0;
                if(this.car != null){
                    if(this.car.dealer.mobile != null){
                        sum = 1;
                    }
                    sum = this.car.dealer.phone.split('/').length + sum;
                }else if(this.dealer != null){
                    if(this.dealer.dealer.mobile != null){
                        sum = 1;
                    }
                    sum = this.dealer.dealer.phone.split('/').length + sum;
                }
                return sum;
            },
        }
    }
</script>

<style scoped>

</style>
