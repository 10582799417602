<template>
    <div class="modal" style="display:flex">

        <div class="modalform">
            <div class="modal__wrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>Email Seller</h1>
                </div>
                <div class="modalform__form">
                    <form method="post" @submit.prevent="sendEmail" @keydown="errors.clear($event.target.name)">
                        <div  class="form-error-top" v-if="Object.keys(errorResponse).length > 0">General problem. Please try again later.  </div>
                        <input type="text" name="name" placeholder="Your Name*" class="form-control"
                               v-model="name" :class="errors.has('name') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('name')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('name')" :key="index"
                                 v-text="error"></div>
                        </div>

                        <input type="email" name="email" placeholder="Your Email Address*" class="form-control"
                            v-model="email" :class="errors.has('email') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('email')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('email')" :key="index"
                                 v-text="error"></div>
                        </div>

                        <input type="text" name="phone" id="phone" placeholder="Your Phone Number" class="form-control"
                               v-model="phone" :class="errors.has('phone') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('phone')">
                            <div class="form-error"  v-for="(error, index) in errors.get('phone')"
                                 :key="index"
                                 v-text="error"></div>
                        </div>

                        <div class="modalform__group" v-show="this.car && this.car.dealer.id != 1">
                            <label>Do you have a trade in?</label>
                            <input type="radio" class="custom-radio" name="seller-tradein" value="No" id="seller-tradein-no"
                                   :checked="!isTradeIn" @click="resetTradeIn"/>
                            <label for="seller-tradein-no" class="custom-radio-label"></label>
                            <label for="seller-tradein-no">No</label>

                            <input type="radio" class="custom-radio" name="seller-tradein" value="Yes" id="seller-tradein-yes"
                                    :checked="isTradeIn" @click="isTradeIn = true"/>
                            <label for="seller-tradein-yes" class="custom-radio-label"></label>
                            <label for="seller-tradein-yes">Yes</label>
                        </div>

                        <div v-if="isTradeIn">
                            <input type="text" placeholder="Trade-in car registration" class="form-control" id="tradein"
                                   v-model="regNumber" name="reg_number"
                                   :class="errors.has('reg_number') ? 'form-control--error' : null"/>

                            <div v-if="errors.has('reg_number')">
                                <div class="form-error"
                                     v-for="(error, index) in errors.get('reg_number')" :key="index"
                                     v-text="error"></div>
                            </div>
                        </div>

                        <textarea name="enquiry" id="enquiry" :placeholder="placeholder" class="form-control"
                                  v-model="enquiry" :class="errors.has('enquiry') ? 'form-control--error' : null"></textarea>
                        <div v-if="errors.has('enquiry')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('enquiry')"
                                 :key="index" v-text="error"></div>
                        </div>

                        <input type="submit" value="Submit" :disabled="errors.any()"/>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }
    }

    export default {
        name: "ModalEmailSeller",
        props: ['car', 'dealer'],

        data: function() {
            return {
                carId: null,
                dealerId: null,
                name: null,
                email: null,
                phone: null,
                enquiry: null,
                isTradeIn: false,
                regNumber: null,
                response: null,
                errorResponse: [],
                errors: new Errors(),
            }
        },
        mounted(){
            if(this.car){
                this.carId = this.car.id;
            }else if(this.dealer){
                this.dealerId = this.dealer.id
            }

            this.loadEnquiryData();
        },
        methods: {
            closeModal() {
                EventBus.$emit('closeModal');
            },

            sendEmail() {
                this.errorResponse = [];
                EventBus.$emit('setIsLoading');
                this.axios.post(process.env.VUE_APP_API_URL  + '/api/general-enquiry/send', {
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone,
                    'enquiry': this.enquiry == null || this.enquiry == "" ? this.placeholder : this.enquiry,
                    'car_id': this.carId,
                    'dealer_id': this.dealerId,
                    'is_trade_in': this.isTradeIn,
                    'reg_number': this.isTradeIn ? this.regNumber : null,
                    'type': this.carId !== null ? 'ad' : 'dealer'
                })
                    .then(response => {
                        EventBus.$emit('closeModal');
                        EventBus.$emit('unsetIsLoading');
                        if(typeof response.data.status !== 'undefined' && response.data.status == 'error') {
                            EventBus.$emit('openStatusMessageModal', {msg: "Your enquiry has been sent. Thank you"});
                        }else{
                            EventBus.$emit('openStatusMessageModal', {msg: "Your enquiry has been sent. Thank you!"});
                        }

                        //Save Enquiry Data
                        let objEnquiry = {
                            name : this.name,
                            email: this.email,
                            phone: this.phone,
                            regNumber: this.regNumber,
                            isTradeIn: this.isTradeIn
                        };
                        this.saveEnquiryLocal(objEnquiry);

                        this.enquiry = null;
                        this.response = response;
                    })
                    .catch(error => {
                        EventBus.$emit('unsetIsLoading');
                        if(error && error.response.status == 422){
                            this.errors.record(error.response.data);
                        }else if(error && error.respoonse && error.response.data){
                            this.errorResponse = error.response.data;
                        }else{
                            this.errorResponse = {'error': 500} ;
                        }
                    });
            },

            loadEnquiryData(){
                let data = JSON.parse(sessionStorage.getItem('enquiry_data'));

                if(data != null){
                    this.name = data.name;
                    this.email = data.email;
                    this.phone = data.phone;
                    this.regNumber = data.regNumber;
                    this.isTradeIn = data.isTradeIn;
                }

            },
            saveEnquiryLocal(data){
                sessionStorage.setItem('enquiry_data', JSON.stringify(data));
            },

            resetTradeIn() {
                this.isTradeIn = false;
            }
        },
        computed:{
            placeholder() {
                if(this.car != null){
                    return 'I would like to enquire about the ' + this.car.reg_year + ' ' + this.car.make + ' ' + this.car.model;
                }else{
                    return '';
                }

            }
        }
    }
</script>

<style scoped>

</style>
