<template>
    <div class="dealerdetails__reviews_review">
        <review-avatar :item="this.review.reviewer_name"></review-avatar>
        <div class="dealerdetails__reviews_detail">
            <h3>{{ this.review.reviewer_name }}</h3>
            <div class="dealerdetails__reviews_rating">
                <div class="stars">
                    <span v-for="(star,idx) in review.rating" v-bind:key="idx"></span>
                    <span class="stars__empty" v-for="(star,idx) in (5-review.rating)" v-bind:key="idx+1000"></span>
                </div>
                <small>{{ fromNow }}</small>
            </div>
            <p>{{ this.review.review }}</p>
        </div>
    </div>
</template>

<script>

    import ReviewAvatar from '../ReviewAvatar'

    var moment = require('moment')
    export default {
        name: "DealerSmallReview",
        props: ['review'],
        components:{
            ReviewAvatar
        },
        computed: {
            fromNow() {
                return moment(this.review.created_at).fromNow();
            }
        }
    }
</script>

<style scoped>

</style>
