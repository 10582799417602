<template>
    <div class="dealerdetails__reviews_avatar">
        <div class="avatar avatar" v-bind:style="{ 'background-color': getBgColour }"> {{ item.charAt(0) }} </div>
    </div>
</template>

<script>
    export default {
        name: "ReviewAvatar",
        props: ['item'],
        computed: {
            getBgColour(){
                let res = this.item.charAt(0).toLowerCase()
                return this.colour[res];
            }
        },
        data: function() {
            return {
                colour: {
                    'a' : 'darkslategrey',
                    'b' : '#f6543d',
                    'c' : 'pink',
                    'd' : 'coral',
                    'e' : 'green',
                    'f' : 'goldenrod',
                    'g' : 'purple',
                    'h' : 'cadetblue',
                    'i' : 'darkblue',
                    'j' : 'brown',
                    'k' : 'lightgreen',
                    'l' : 'red',
                    'm' : 'indigo',
                    'n' : 'blue',
                    'o' : 'crimson',
                    'p' : 'orange',
                    'r' : 'green',
                    's' : 'slateblue',
                    't' : 'lightgrey',
                    'u' : 'lightseagreen',
                    'v' : 'teal',
                    'x' : 'tomato',
                    'y' : 'pink',
                    'w' : 'darkcyan',
                    'z' : 'dodgerblue'
                },
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
</style>
