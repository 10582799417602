<template>
    <div class="dealerdetails__team_person">
        <div class="dealerdetails__team_profilepic">
            <v-lazy-image :src="getImage" :src-placeholder="placeholderLazyImg" alt="Team Member" width="164" height="164" />
        </div>
        <div class="dealerdetails__team_persondata">
            <dl>
                <dt>{{ this.teamMember.name }}</dt>
                <dd>{{ this.teamMember.position }}</dd>
            </dl>
        </div>
    </div>
</template>



<script>
    import VLazyImage from "v-lazy-image"

    export default {
        name: "DealerTeamMember",
        props: ['teamMember'],
        components:{
            VLazyImage
        },
        computed: {
            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            getImage(){
                if (this.teamMember.has_photo) {
                    return this.teamMember.photo_url;
                } else {
                    return require('../../../assets/img/default-avatar.png');
                }
            },
            imageStyle() {
                if (this.teamMember.has_photo) {
                    // return "background-image: url('" + this.teamMember.photo_url + "');";
                    return "background-image: url('" + require('../../../assets/img/default-avatar.png') + "');";
                } else {
                    return "background-image: url('" + require('../../../assets/img/default-avatar.png') + "');";
                }
            }
        },
    }
</script>

<style scoped>

</style>
