<template>
    <div>
        <dl :class="{ current: activeMonday }">
            <dt>Monday:</dt>
            <dd>{{ hoursMonday }}</dd>
        </dl>
        <dl :class="{ current: activeTuesday }">
            <dt>Tuesday:</dt>
            <dd>{{ hoursTuesday }}</dd>
        </dl>
        <dl :class="{ current: activeWednesday }">
            <dt>Wednesday:</dt>
            <dd>{{ hoursWednesday }}</dd>
        </dl>
        <dl v-bind:class="{ current: activeThursday }">
            <dt>Thursday:</dt>
            <dd>{{ hoursThursday }}</dd>
        </dl>
        <dl v-bind:class="{ current: activeFriday }">
            <dt >Friday:</dt>
            <dd>{{ hoursFriday }}</dd>
        </dl>
        <dl v-bind:class="{ current: activeSaturday }">
            <dt>Saturday:</dt>
            <dd>{{ hoursSaturday }}</dd>
        </dl>
        <dl v-bind:class="{ current: activeSunday }">
            <dt>Sunday:</dt>
            <dd>{{ hoursSunday }}</dd>
        </dl>
    </div>
</template>

<script>
    var moment = require('moment')
    export default {
        name: "DealerHours",
        props: ['hours'],

        data: function() {
            return {
                hoursMonday: null,
                hoursTuesday: null,
                hoursWednesday: null,
                hoursThursday: null,
                hoursFriday: null,
                hoursSaturday: null,
                hoursSunday: null,
            };
        },

        computed: {
            activeMonday() {
                return moment().weekday() == 1;
            },
            activeTuesday() {
                return moment().weekday() == 2;
            },
            activeWednesday() {
                return moment().weekday() == 3;
            },
            activeThursday() {
                return moment().weekday() == 4;
            },
            activeFriday() {
                return moment().weekday() == 5;
            },
            activeSaturday() {
                return moment().weekday() == 6;
            },
            activeSunday() {
                return moment().weekday() == 7;
            },
        },

        mounted() {
            let index;
            let element;
            let hours;

            for (index = 0; index < this.hours.length; index++) {
                element = this.hours[index];
                if (element.optional_text != '') {
                    hours = element.optional_text;
                } else if (element.open == '' || element.open == ':' || element.closed == '' || element.closed == ':') {
                    hours = 'Closed';
                } else {
                    hours = element.open + ' to ' + element.closed;
                }
                switch(element.dow) {
                    case 1:
                        this.hoursMonday = hours;
                        break;
                    case 2:
                        this.hoursTuesday = hours;
                        break;
                    case 3:
                        this.hoursWednesday = hours;
                        break;
                    case 4:
                        this.hoursThursday = hours;
                        break;
                    case 5:
                        this.hoursFriday = hours;
                        break;
                    case 6:
                        this.hoursSaturday = hours;
                        break;
                    case 7:
                        this.hoursSunday = hours;
                        break;
                }
            }
        },

        methods: {

        }
    }
</script>

<style scoped>

</style>
